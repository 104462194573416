const API_URL = "/api/www/2.0.0";
const CONTENT_API = "/api";

export default {
  map: {
    mapFilters: `${CONTENT_API}/map/filters`,
    mapSearchList: `${CONTENT_API}/map/search`,
    mapMarkers: `${CONTENT_API}/map/markers`,
    reverseGeocoding: "https://api.mapbox.com/search/geocode/v6/reverse",
  },
  form: {
    askQuestion: `${API_URL}/contact/ask_question`,
    getOffer: `${API_URL}/contact/get_offer`,
    becomePartner: `${API_URL}/contact/become_partner`,
  },
  setCookies: `${CONTENT_API}/cookies`,
  supplier: {
    details: (supplierId: string) => `${CONTENT_API}/supplier/${supplierId}`,
  },
  table: {
    filters: `${CONTENT_API}/table/filters`,
    facilities: `${CONTENT_API}/table/facilities`,
  },
};
